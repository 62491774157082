import { Button, Typography } from '@mui/material'
import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { getUrlParamWithID } from '../utils/base64'

export interface IHomeImage {
	src: string
	wide: boolean
	categoryId: number
	text: string
}

export default function HomeImage(props: IHomeImage) {
	const [hover, setHover] = useState(false)
	const { language } = useParams()

	const handleMouseEnter = () => setHover(true)
	const handleMouseLeave = () => setHover(false)

	return (
		<Link
			to={`/${language}/${getUrlParamWithID(props.text, props.categoryId, 'c')}`}
			style={{
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				position: 'relative',
			}}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<img
				src={`${process.env.REACT_APP_IMAGE_URL}/home/${props.src}`}
				width={'100%'}
			/>
			<Button
				sx={{
					position: 'absolute',
					top: props.wide ? '50%' : '80%',
					left: props.wide ? '90%' : '50%',
					transform: 'translate(-50%, -50%)',
					borderRadius: '0px',
					backgroundColor: hover ? 'secondary.main' : 'primary.main',
					color: hover ? 'primary.main' : 'secondary.main',
					padding: '12px 25px',
					boxShadow: 'none',
					transition: 'background-color 0.3s',
					'&:hover': {
						backgroundColor: 'secondary.main',
						color: 'primary.main',
						boxShadow: 'none',
					},
				}}
				variant='contained'
			>
				<Typography
					fontFamily={'Avenir'}
					fontSize={'0.7857rem !important'}
					fontWeight={'bold'}
					sx={{
						letterSpacing: '2px',
					}}
				>
					{props.text}
				</Typography>
			</Button>
		</Link>
	)
}
