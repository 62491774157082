import { useState } from 'react'
import DropdownMenu, { IDropdownMenuProps } from '../../dropdown/dropdown-body'
import { Box } from '@mui/material'
import '../../../assets/scss/dropdown.scss'
import '../../../translations'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import '../../../assets/scss/header.scss'

export interface IDropDown extends IDropdownMenuProps {
	label: string
	isLeftSide: boolean
	isSticky?: boolean
	id?: string
	component?: JSX.Element
	language: string
	isCategory: boolean
}

function Dropdown(props: IDropDown) {
	const { t } = useTranslation()

	const [isDropdownVisible, setDropdownVisible] = useState(false)

	const handleMouseEnter = () => {
		setDropdownVisible(true)
	}

	const handleMouseLeave = () => {
		setDropdownVisible(false)
	}

	const hasLinks = props.children && props.children.length > 0

	return (
		<Box
			sx={{
				display: 'inline-flex',
				borderColor: 'grey.500',
				cursor: 'pointer',
				padding: props.isSticky ? '0 0 15px' : '0 0 25px',
				margin: props.isLeftSide
					? '0 25px 0 0 !important'
					: '0 0 0 15px !important',
				textDecoration: 'none',
				color: 'primary.main',
			}}
			className='dropdown header-dropwdown'
			onMouseEnter={props.children && handleMouseEnter}
			onMouseLeave={props.children && handleMouseLeave}
		>
			{props.component ||
				(!props.isCategory ? (
					<Link
						to={`/${props.language}/${props.id}`}
						style={{
							textDecoration: 'none',
							color: 'black',
						}}
					>
						{t(props.label)}
						{isDropdownVisible && hasLinks && (
							<Box
								className={
									props.isLeftSide ? 'dropdown-menu' : 'dropdown-menu-right'
								}
							>
								<DropdownMenu
									isCategory={props.isCategory}
									children={props.children}
								/>
							</Box>
						)}
					</Link>
				) : (
					<span className='dropdown-link'>
						{t(props.label)}
						{isDropdownVisible && hasLinks && (
							<Box className='dropdown-menu'>
								<DropdownMenu
									isCategory={props.isCategory}
									children={props.children}
								/>
							</Box>
						)}
					</span>
				))}
		</Box>
	)
}

export default Dropdown
