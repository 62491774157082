import { List, ListItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { IDropdownLink } from '../../interfaces/dropdown-link.interface'
import i18n from '../../translations'
import SubDropdown from '../header/dropdown/sub-dropdown'
import { toTitleCase } from '../utils'
import { getUrlParamWithID } from '../utils/base64'

export interface IDropdownMenuProps {
	children?: IDropdownLink[]
	isCategory: boolean
}

const DropdownMenu = (props: IDropdownMenuProps) => {
	const language = i18n.language
	const { t } = useTranslation()

	return (
		<List>
			{props.children?.map((link, index) => {
				const hasSubMenu = !!link.subMenus?.length
				return hasSubMenu ? (
					<ListItem
						key={index}
						sx={{
							padding: '0 !important',
							margin: '0 !important',
						}}
					>
						{hasSubMenu ? (
							<Link
								to={`/${language}/${
									props.isCategory
										? getUrlParamWithID(link.name, Number(link.id), 'c')
										: link.id
								}`}
								color={'#4d4e52'}
								style={{
									textDecoration: 'none',
									color: '#4d4e52',
									padding: '0',
									margin: '0',
								}}
								onClick={link.onClick || (() => {})}
							>
								<SubDropdown
									label={toTitleCase(t(link.name))}
									isLeftSide={true}
									language={language}
									isCategory={props.isCategory}
									children={link.subMenus}
								/>
							</Link>
						) : (
							toTitleCase(t(link.name))
						)}
					</ListItem>
				) : (
					<Link
						to={`/${language}/${
							props.isCategory
								? getUrlParamWithID(link.name, Number(link.id), 'c')
								: link.id
						}`}
						color={'#4d4e52'}
						style={{
							textDecoration: 'none',
							color: '#4d4e52',
							padding: '0',
							margin: '0',
						}}
						onClick={link.onClick || (() => {})}
					>
						<ListItem key={index}>{toTitleCase(t(link.name))}</ListItem>
					</Link>
				)
			})}
		</List>
	)
}

export default DropdownMenu
