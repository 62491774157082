import { IEntityIdentifier } from '../redirect-handler/redirect-handler'

function encodeBase64(str: string): string {
	return btoa(str)
}

function decodeBase64(str: string): string {
	return atob(str)
}

function textToSlug(text: string): string {
	text = text.trim().toLowerCase()

	const accentMap: Record<string, string> = {
		ã: 'a',
		à: 'a',
		á: 'a',
		ä: 'a',
		â: 'a',
		ẽ: 'e',
		è: 'e',
		é: 'e',
		ë: 'e',
		ê: 'e',
		ì: 'i',
		í: 'i',
		ï: 'i',
		î: 'i',
		õ: 'o',
		ò: 'o',
		ó: 'o',
		ö: 'o',
		ô: 'o',
		ù: 'u',
		ú: 'u',
		ü: 'u',
		û: 'u',
		ñ: 'n',
		ç: 'c',
		'·': '-',
		'/': '-',
		_: '-',
		',': '-',
		':': '-',
		';': '-',
	}

	text = text
		.replace(/[^\w\s-]/g, (char) => accentMap[char] || '')
		.replace(/\s+/g, '-')
		.replace(/--+/g, '-')

	return text
}

function getUrlParamWithID(
	name: string,
	id: number | undefined,
	type: 'p' | 'c'
): string {
	if (!id) {
		return ''
	}

	return `${type}/${textToSlug(name)}/${id}`
}

// 2024-10-06 we mantain this function code for compatibility reason.
// In the future should be deleted
function getEntityEID(searchParams: URLSearchParams): number | null {
	const eid: string | null = searchParams.get('eid')

	const eidParsed = eid ? JSON.parse(decodeBase64(eid)) : null

	return !eid ? null : Number(eidParsed.id)
}

export {
	encodeBase64,
	decodeBase64,
	textToSlug,
	getUrlParamWithID,
	getEntityEID,
}
