import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	List,
	ListItem,
	Typography
} from '@mui/material'
import { Link } from 'react-router-dom'
import { LanguageISOCode } from '../../../../enums/language-iso-code.enum'
import { IDropdownLink } from '../../../../interfaces/dropdown-link.interface'
import { getUrlParamWithID } from '../../../utils/base64'

export interface IMobileHeaderTabPanelProps {
	mobile?: boolean
	toggleDrawer: () => void
	language: LanguageISOCode
	categories?: IDropdownLink[]
}

const MobileHeaderTabPanel = (props: IMobileHeaderTabPanelProps) => {
	return (
		<List>
			{props.categories &&
				props.categories.length > 0 &&
				props.categories.map((link, index) => {
					return link.subMenus && link.subMenus.length > 0 ? (
						<ListItem
							key={index}
							sx={{
								display: 'block',
								padding: '0',
							}}
						>
							<Accordion
								sx={{
									boxShadow: 'none',
									border: 'none',
								}}
							>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls={`panel${index}-content`}
									id={`panel${index}-header`}
								>
									<Typography>{link.name}</Typography>
								</AccordionSummary>
								<AccordionDetails
									sx={{
										padding: '0',
										paddingLeft: '20px',
									}}
								>
									<MobileHeaderTabPanel
										mobile={props.mobile}
										toggleDrawer={props.toggleDrawer}
										language={props.language}
										categories={link.subMenus}
									/>
								</AccordionDetails>
							</Accordion>
						</ListItem>
					) : (
						<ListItem
							key={index}
							sx={{
								display: 'block',
								padding: '16px',
							}}
						>
							<Link
								color={'#4d4e52'}
								style={{
									lineHeight: '1.75',
									color: '#000',
									textDecoration: 'none',
									padding: '16px 0',
								}}
								to={`/${props.language}/${getUrlParamWithID(link.name, Number(link.id), 'c')}`}
								onClick={props.toggleDrawer}
							>
								{link.name}
							</Link>
						</ListItem>
					)
				})}
		</List>
	)
}

export default MobileHeaderTabPanel
