import { Box, Typography } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import { IRoute } from '../../interfaces/route.interface'
import { getUrlParamWithID } from '../utils/base64'
import { ICategory } from './category'

interface ICategoryNavigationBar extends IRoute {
	children: ICategory[]
}

export const CategoryNavigationBar = (props: ICategoryNavigationBar) => {
	const { language } = useParams()
	const centered = !props.isMobile
		? {
				justifyContent: 'center',
		  }
		: {}

	return (
		<Box
			sx={{
				margin: '10px auto',
				width: '100%',
				display: 'flex',
				flexDirection: 'row',
				flexWrap: 'nowrap',
				overflowX: 'auto',
				overflowY: 'hidden',
				'::-webkit-scrollbar': {
					display: 'none',
				},
				'& a': {
					padding: '6px 15px',
					textDecoration: 'none',
					color: '#707175',
					whiteSpace: 'nowrap',
				},
				...centered,
			}}
		>
			{props.children.map((item, index) => (
				<Link
					key={item.externalReference}
					to={`/${language}/${getUrlParamWithID(item.name, item.id, 'c')}`}
				>
					<Typography className='underlined-link'>{item.name}</Typography>
				</Link>
			))}
		</Box>
	)
}

export default CategoryNavigationBar
